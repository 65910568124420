import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Posts = ({ data: posts }) => {
  return (
    <>
      <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {posts.map((post) => (
          <li
            key={post.id}
            className="flex flex-col justify-items-stretch mb-2 border border-black border-opacity-10 p-4 rounded"
          >
            <div className="text-center" style={{ height: '12rem' }}>
              {post.featuredImage ? (
                <GatsbyImage
                  image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                  imgStyle={{ objectFit: 'contain' }}
                  alt={post.title}
                  className="max-w-full max-h-full"
                />
              ) : (
                <img
                  src="https://via.placeholder.com/220x220?text=No+img"
                  alt="placeholder"
                  className="max-w-full max-h-full inline-block"
                />
              )}
            </div>
            <p className="uppercase mt-4 flex justify-between">
              <span className="text-primary mr-4 text-xs">
                {(post.categories?.nodes?.length &&
                  post.categories?.nodes.map(({ name = '' }, index) => {
                    if (index > 1) {
                      return `> ${name}`;
                    }

                    return name;
                  })) ||
                  'Uncategorised'}
              </span>
              <span className="gray-500 text-xs">{post.date}</span>
            </p>
            <h4 className="font-normal leading-6">{post.title}</h4>
            <div
              className="my-2 entry-content"
              dangerouslySetInnerHTML={{ __html: post.excerpt }}
            />
            <Link className="text-primary hover:underline" to={`/post/${post.slug}`}>
              See more
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

Posts.propTypes = {
  data: PropTypes.array,
};

Posts.defaultProps = {
  data: [],
};

export default Posts;
