import React from 'react';

function BlogSidebar() {
  return (
    <div>
      <a
        className="twitter-timeline"
        data-tweet-limit="8"
        href="https://twitter.com/CtznShareholder"
      >
        Recent tweets by @CtznShareholder
      </a>
    </div>
  );
}
export default BlogSidebar;
