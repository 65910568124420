import React from 'react';
import Layout from '@org/components/layout';
import PropTypes from 'prop-types';
import Seo from '@org/components/seo';
import Posts from '@shared/components/wp-components/posts';
import BlogSidebar from '@shared/components/blog-sidebar';
import { graphql, useStaticQuery } from 'gatsby';

const Page = ({ title }) => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: [date] }) {
        nodes {
          id
          title
          excerpt
          date(formatString: "MMMM Do, YYYY")
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, layout: CONSTRAINED)
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  `);

  const { allWpPost: dataPosts } = data;

  return (
    <Layout>
      <Seo title="Blog" />
      <div className="container mx-auto p-4">
        <h3>Blog</h3>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="w-full md:w-3/4 h-full bg-grey:700">
            <Posts data={dataPosts.nodes} />
          </div>
          <div className="w-full md:w-1/4 ml-0 md:ml-4 mt-0 md:-mt-2">
            <BlogSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
};

export default Page;
